<template>
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="img-container">
        <img src="@/assets/motifs/faces.png" />
      </div>
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <div v-scrollreveal class="sr_prevent_flickering">
            <h1>Merci !</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FloralSection class="tipis">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 first-part"
    >
      <h3>
        Merci encore à tous pour votre présence, vos sourires, vos mots doux !
      </h3>
      <p>
        On a passé un merveilleux week-end, et notre seul regret - comme on
        pouvait s’y attendre - est de ne pas avoir pu passer plus de temps avec
        chacun d’entre vous 😍
      </p>
      <img
        src="@/assets/motifs/pampas-separator.png"
        class="pampas-separator"
      />
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 first-part"
    >
      <h3>Les photos de la photographe</h3>
      <p>
        Comme vous l'avez peut-être remarqué, une photographe était présente et
        ses photos sont maintenant disponibles ci-dessous. Elles sont
        téléchargeables en pleine qualité, pour profil Tinder, profil LinkedIn,
        tirage en A0, etc...
      </p>
      <Button
        link="https://photos.app.goo.gl/GuC2rwpAHQz4QpuQ8"
        target="_blank"
        raised
        class="centered-cta"
        >C'est par ici !</Button
      >
      <h3>Les photos du photobooth</h3>
      <p>
        Comme promis on les a toutes récupérées et mises dans l'album
        ci-dessous. Vous avez l'air de vous être bien amusés... ça nous a fait
        trop plaisir de les regarder !
      </p>
      <Button
        link="https://photos.app.goo.gl/C1AADhjPBp8Vpg5f6"
        target="_blank"
        raised
        class="centered-cta"
        >C'est par là !</Button
      >
      <p class="button-subtext">
        <i
          >Les originaux (sans cadre etc) sont dans un Drive
          <a
            href="https://drive.google.com/drive/folders/1md4I3pq1hBYjFaLK8DihOkq9Oh_nnTF2?usp=sharing"
            target="_blank"
            >ici.</a
          ></i
        >
      </p>
      <h3>Vos photos à vous</h3>
      <p>
        Vous pouvez partager ci-dessous les photos et vidéos que vous avez pu
        prendre, ainsi que voir celles des autres. Si vous n'arrivez pas à
        déposer les vôtres vous pouvez toujours nous les envoyer on s'en
        occupera!
      </p>
      <Button
        link="https://photos.app.goo.gl/pLmxFRdjuZtkSnZ27"
        target="_blank"
        outlined
        class="centered-cta"
        >Photothèque partagée</Button
      >
      <h3>La vidéo du mariage</h3>
      <p>
        Notre ami
        <a href="https://mathieuserra.fr/" target="_blank">Mathieu Serra</a> a
        proposé de prendre quelques images au passage; plutôt cool, surtout
        qu'il est bourré de talent. Il nous a monté ça en une petite vidéo
        accessible sur la photothèque partagée mais on vous la met direct là
        aussi, effet waouh garanti.
      </p>
      <Button
        link="https://photos.google.com/share/AF1QipMSLTr_5CToK3z5vRD5-NoE1v8pbJUVyffH1WoFGMD3LRpeGKWkdwqGrZrCPmtNew/photo/AF1QipN4brRf9Ow4hddToRFLwMS-jiyLQdMoQrh4FYGD?key=R2p5V3pkMDVWUjJXX1JuOTAtMW9sSEdOWV9ZNFdR"
        target="_blank"
        outlined
        class="centered-cta"
        >La vidéo</Button
      >
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
      <img src="@/assets/images/mariage1.jpg" class="grid-cell-image" />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
      <img src="@/assets/images/mariage2.jpg" class="grid-cell-image" />
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 grid-tablet-offset-3"
    >
      <img src="@/assets/images/mariage3.jpg" class="grid-cell-image" />
    </div>
  </FloralSection>
</template>

<script>
import FloralSection from "@/components/atoms/FloralSection.vue";
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "HomeView",
  components: { FloralSection, Button },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include gradient-background;
  margin-top: -56px;
  padding-top: 56px;
  text-align: center;

  h1 {
    margin: 50px 0;
    color: white;
    font-weight: 900;
  }

  .mdc-layout-grid {
    position: relative;
  }

  .img-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  img {
    width: 270px;
    opacity: 0;
    max-width: 90%;
    -moz-animation: cssAnimation 200ms ease-in 300ms forwards;
    -webkit-animation: cssAnimation 200ms ease-in 300ms forwards;
    -o-animation: cssAnimation 200ms ease-in 300ms forwards;
    animation: cssAnimation 200ms ease-in 300ms forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}

@keyframes cssAnimation {
  to {
    opacity: 0.7;
  }
}

.first-part {
  text-align: center;

  h4 {
    font-style: italic;
    margin-bottom: 50px;
  }

  .pampas-separator {
    width: 90%;
  }
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;

    h1 {
      margin: 70px 0;
    }

    img {
      width: 345px;
    }
  }
}

@media (min-width: $mdc-grid-desktop) {
  .header {
    h1 {
      font-size: 2.5em;
      margin: 100px 0;
    }

    img {
      width: 475px;
    }
  }
}

.whatsapp-button {
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  padding: 10px 16px;
  color: white;
  font-weight: 700;
  width: fit-content;
  margin: auto;
  transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;

  svg {
    fill: white;
    height: 30px;
    margin-right: 8px;
  }

  &:hover {
    transform: translateY(-4px);
    background-color: #58e18a;
  }
}

.centered-cta-subtitle {
  text-align: center;
  font-style: italic;
  margin-top: -12px;
}

.button-subtext {
  margin-top: -20px;
  margin-bottom: 30px;
  font-size: 0.9em;
}
</style>
