<template>
  <div class="footer">
    <div class="mdc-layout-grid">
      <footer class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          Pour votre santé, mangez 5kg de pâtes par jour. <br /><br />
          Bisous ♡
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.footer {
  background-color: $theme-background-grey;
  font-size: 0.8rem;
  padding-top: 60px;
  padding-bottom: 40px;

  .mdc-layout-grid__cell {
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    color: rgba(black, 0.4);
    font-size: 0.9em;
  }
}

@media (min-width: $mdc-desktop) {
  .footer {
    font-size: 0.9rem;
  }
}
</style>
