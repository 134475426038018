import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ScrollReveal from "scrollreveal";
// ICONS
import BurgerIcon from "./components/svgs/Burger.vue";
import WhatsappLogo from "./components/svgs/Whatsapp.vue";

const inline_svgs = [BurgerIcon, WhatsappLogo];

const app = createApp(App).use(store).use(router);

inline_svgs.forEach((item) => {
  app.component(`Svg${item.name}`, item);
});

// make v-scrollreveal usable in all components
app.directive("scrollreveal", {
  mounted: (el, binding) => {
    const defaultTransition = {
      distance: "100px",
      origin: "bottom",
      duration: 1000,
    };
    if (binding.value) {
      ScrollReveal().reveal(el, { ...defaultTransition, ...binding.value });
    } else {
      ScrollReveal().reveal(el, defaultTransition);
    }
  },
  beforeUnmount: (el) => {
    ScrollReveal().clean(el);
  },
});

app.mount("#app");
