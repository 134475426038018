import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TipisView from "../views/TipisView.vue";
import ContactView from "../views/ContactView.vue";
import InfosView from "../views/InfosView.vue";

const routes = [
  {
    path: "",
    name: "Accueil",
    component: HomeView,
  },
  {
    path: "/infos",
    name: "Infos",
    component: InfosView,
  },
  {
    path: "/tipis",
    name: "Tipis",
    component: TipisView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
