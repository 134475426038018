<template>
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <div v-scrollreveal class="sr_prevent_flickering">
            <h1>Village Tipi</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FloralSection class="tipis">
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <p>
        Comme expliqué sur la page d’accueil, il sera possible de dormir sur
        place. La Manade dispose d’un grand champ dans lequel les tipis seront
        installés (promis, vous aurez moins de 100m à faire pour arriver jusqu’à
        votre tente). C’est simple, pratique, et c’est la solution qu’on a
        trouvé pour ne pas avoir à s’inquiéter de vous voir prendre la route
        alcoolisés (et aussi on trouve ça joli).
      </p>
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 sr_prevent_flickering noselect"
      v-scrollreveal="{ delay: 300 }"
      @click="$refs.squigglesDialog.open()"
    >
      <img
        src="@/assets/images/village-tipi.jpeg"
        class="grid-cell-image --banner"
        alt="Village Tipi"
      />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Équipements</h3>
      <p>
        Les tipis sont équipés de matelas et sur-matelas posés au sol avec
        oreillers et couettes. Le linge (draps et serviettes de toilettes) est
        fourni.
      </p>
      <p>
        Des cabines de douche en bois seront également installées dans le champ.
        Il y aura de l’eau chaude ne vous inquiétez pas (chauffe eau solaire
        hybride) et des savons et shampoings bio seront à disposition dans les
        douches. L’eau de douche sera rejetée plus loin dans le champ - nous
        vous demanderons donc de ne pas utiliser d’autres produits que ceux
        fournis.
      </p>
      <p>
        Petite précision: il n’y aura pas d’électricité dans les tipis.
        Néanmoins des lanternes-led seront disponibles dans chaque tipi pour
        avoir un peu de lumière le soir (si vous voulez aussi ramener vos
        frontales n’hésitez pas). Une zone de recharge avec des multiprises sera
        mise en place dans la salle, vous pourrez donc y laisser votre téléphone
        pour la nuit ou venir le recharger en journée.
      </p>
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Options</h3>
      <p>
        Les tipis font tous la même taille (5m de diamètre et une hauteur de 3m
        au centre). Il y a cependant deux types de tipis disponibles :
      </p>
      <ul>
        <li>les tipis 6 places équipés de lits simples</li>
        <li>les tipis 4 places équipés de deux lits doubles.</li>
      </ul>
      <p>
        Les tipis à 4 sont un peu plus cosy (plus de place par personne, lits
        faits à l’arrivée, tables de chevet et coussins/plaids supplémentaires).
      </p>
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 grid-tablet-offset-2"
    >
      <img src="@/assets/images/tipi6.jpeg" class="grid-cell-image" />
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 grid-tablet-offset-2"
    >
      <img src="@/assets/images/douches.jpeg" class="grid-cell-image" />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Programme</h3>
      <p>
        Les tipis seront installés dès le vendredi, donc vous pouvez y loger dès
        le vendredi soir: pratique si vous venez de loin! Nous y serons, et ce
        sera l’occasion de passer un moment tranquille avant le jour J. On
        commandera probablement des pizzas ou un truc simple pour manger le
        soir.
      </p>
      <p>
        Le lendemain on installera dans la grande salle de quoi se faire beaux
        tous ensemble (miroirs, sèche-cheveux, etc).
      </p>
      <p>Il faudra libérer les tipis dans la journée du dimanche.</p>
    </div>

    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Inscription</h3>
      <p>
        Pour nous aider à payer le prestataire, nous demandons de participer à
        hauteur de 60€/personne pour les tipis à 6, et 75€/personne pour les
        tipis à 4. Cela couvre évidemment <b>les deux nuits</b> si vous venez
        dès le vendredi.
      </p>
      <p>
        Pour la participation, le plus simple est d’utiliser cette
        <a
          href="https://lydia-app.com/pots?id=48525-participation-tipis"
          target="_blank"
          >cagnotte Lydia dédiée</a
        >
        ou de passer par Paypal (<router-link to="/contact"
          >vous avez nos numéros</router-link
        >). Sinon, virement (demandez nous notre RIB), chèque, espèces… ce qui
        vous arrange. Idéalement, essayez de faire un paiement groupé par tipi,
        c’est plus simple pour nous :)
      </p>
      <p>
        Si vous êtes intéressés, <b>composez votre tipi</b> de 6 ou de 4 et
        utilisez le formulaire ci-dessous pour réserver vos places. Si votre
        tipi n’est pas complet vous pouvez quand même vous inscrire et on vous
        combinera avec un autre tipi incomplet.
      </p>
      <p>Les places sont limitées, ne trainez pas !</p>
      <Button
        link="https://fr10juin2023.surveysparrow.com/s/Tipis/tt-imEzDCQ55pAJMozntXSFak"
        target="_blank"
        raised
        class="centered-cta"
        >Réserver ma place</Button
      >
    </div>
  </FloralSection>
</template>

<script>
import FloralSection from "@/components/atoms/FloralSection.vue";
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "TipisView",
  components: { FloralSection, Button },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include gradient-background;
  margin-top: -56px;
  padding-top: 56px;
  text-align: center;

  h1 {
    margin: 50px auto;
    color: white;
    font-weight: 900;
  }
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;
  }
}
</style>
