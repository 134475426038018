<template>
  <svg
    viewBox="0 0 1970 1979"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M1121.6361008 1788.25229722c-44.3750355 7.54583937-88.97923785 11.55417591-112.47508998 11.06667552-172.81263825-.51250041-313.70441763-39.22503138-443.27535462-117.6250941-16.57501326-10.06250805-31.0416915-12.58751007-50.39587365-7.5416727-87.10006968 24.11668596-174.81680652 45.73753659-262.04604297 68.85422175-18.35418135 5.53333776-20.10418275 1.51666788-15.44167902-15.0833454 23.10835182-82.93339968 44.86253589-165.3751323 68.74588833-247.32519786 6.64167198-23.11668516 4.27500342-40.71669924-8.92917381-61.33754907-179.22097671-288.0418971-171.67930401-646.96718424 28.90418979-913.4173974 208.37933337-277.48772199 491.66289333-391.10031288 830.61316449-318.72108831 334.0419339 70.39588965 546.95043756 286.03356216 625.24633353 618.34632801 106.94591889 454.44619689-201.48349452 906.36739176-660.94636209 982.78411956Zm821.55482391-1029.55082364C1835.220005 310.79694859 1449.91136342 5.27587084 984.14015747.00086662c-61.3333824 1.00416747-125.55010044 6.02083815-189.02515122 19.35418215C145.36448645 150.93015403-191.3232829 871.309897 125.39363714 1452.95202898c8.15833986 15.57501246 8.80417371 28.16252253 4.27083675 44.24586873-42.10836702 153.32512266-83.8334004 306.65024532-125.81260065 459.46286757-6.7916721 25.14585345-6.7916721 25.14585345 18.35418135 18.61251489 158.98762719-41.72503338 317.72108751-82.9583997 476.57954793-125.17093347 13.17917721-3.52500282 23.5000188-2.03333496 35.54586177 4.01666988 204.62099703 104.55008364 420.15866946 135.23344152 645.86301669 91.50423987 539.27959809-104.57925033 891.69238002-653.04635577 762.99644373-1186.92178287Z"
    />
    <path
      d="M1241.26952984 1453.96036312c-35.05419471 1.51666788-68.48755479-6.5416719-102.05008164-15.60001248-225.57934713-64.83755187-394.86281589-206.09183154-530.86709136-392.10448035-51.40004112-69.88338924-99.01257921-142.25844714-114.84592521-229.22935005-18.99168186-104.57508366 11.04584217-193.0418211 88.85423775-265.44604569 24.75835314-22.62085143 100.14591345-32.66252613 130.31260425-18.09168114 11.19584229 5.53333776 18.35418135 15.59584581 23.00835174 26.15002092 28.52085615 67.87088763 56.67921201 134.71677444 84.57923433 202.58766207 5.27917089 13.07917713 3.01250241 25.63752051-2.88333564 38.71253097-14.33751147 31.17085827-37.34586321 56.2917117-60.71671524 80.92089807-17.7083475 18.12084783-18.47918145 34.69586109-5.40417099 56.31671172 79.68756375 132.71260617 189.90015192 229.23351672 334.06276725 287.0418963 21.49168386 8.5416735 37.7083635 5.53333776 52.01670828-12.56251005 24.01668588-29.65002372 49.39587285-59.32504746 72.14589105-89.98340532 15.57501246-21.62085063 33.43752675-24.62918637 55.416711-14.59167834 47.64587145 21.62085063 95.25840954 44.24170206 142.90011432 66.87088683 10.68750855 4.52917029 21.23751699 10.06250805 31.7916921 15.07917873 48.25420527 24.14585265 47.76670488 24.63335304 43.62086823 77.91256233-8.05417311 104.06258325-86.09173554 155.35845762-178.60014288 180.99181146-20.35418295 5.51250441-41.97920025 6.02917149-63.34171734 5.02500402Z"
    />
  </svg>
</template>

<script>
export default { name: "WhatsappLogo" };
</script>
