<template>
  <div class="mdc-typography wedding-mdc">
    <Navigation />
    <div class="wedding-page-content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/organisms/Navigation.vue";
import Footer from "@/components/organisms/Footer.vue";

export default {
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="scss">
@use "sass:map";
@use "@material/theme" with (
  $primary: #609682,
  $secondary: #eae5ab,
  $background: #fffbff,
  $surface: #f5f7f5,
  $on-surface: #101e18
);

@import "src/assets/variables";

// Import styles for MDC Web components
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/typography/mdc-typography";
@import "@material/ripple/mdc-ripple";
@import "@material/button/mdc-button";
@import "@material/drawer/mdc-drawer";
@import "@material/icon-button/mdc-icon-button";
@import "@material/list/mdc-list";
@import "@material/top-app-bar/mdc-top-app-bar";
@import "@material/dialog/mdc-dialog";

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: $theme-background-grey;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.wedding-mdc {
  min-height: 100vh;
  margin: 0;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  p,
  li {
    a {
      color: $theme-brown;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    b {
      font-weight: 600;
    }
  }

  .mdc-layout-grid {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (min-width: $mdc-layout-grid-max-width) {
    .mdc-layout-grid {
      width: $mdc-layout-grid-max-width;
    }
  }
}

.mdc-typography {
  color: black;
}

/* Adjustements for top app bar display */
.wedding-page-content {
  padding-top: 56px;
}

.grid-cell-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  max-height: 320px;

  &.--banner {
    height: calc(100vw / 4);
    min-height: 150px;
    max-height: 260px;
  }
}

@media (min-width: 600px) and (max-width: 839px) {
  .grid-tablet-offset-2 {
    grid-column-start: 2;
  }
  .grid-tablet-offset-3 {
    grid-column-start: 3;
  }
}

/* Yes, mdc top app bar hase a different breakpoint than mdc grid */
@media (min-width: 600px) {
  .wedding-page-content {
    padding-top: 64px;
  }
}

h2 {
  margin-top: 0;
  font-size: 1.8rem;
}
h4 {
  font-size: 1rem;
  max-width: 800px;
  margin: auto;
  font-weight: 400;
  opacity: 0.7;
}

@media (min-width: $mdc-desktop) {
  h2 {
    font-size: 2.2rem;
  }
  h4 {
    font-size: 1.1rem;
  }
}

.mdc-layout-grid__inner {
  max-width: 1100px;
  margin: auto;
}

.centered-cta {
  margin: 35px auto;
  display: block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
