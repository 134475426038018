<template>
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="img-container">
        <img src="@/assets/motifs/faces.png" />
      </div>
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <div v-scrollreveal class="sr_prevent_flickering">
            <h1>10 Juin 2023</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FloralSection class="tipis">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 first-part"
    >
      <h3>
        À l'occasion de notre 10ème anniversaire de couple, nous nous marions !
      </h3>
      <h4>Hop, d’une pierre deux coups.</h4>

      <p>
        Vous trouverez sur ce site un récapitulatif des informations les plus à
        jour concernant le mariage.
      </p>
      <p>
        Pour les changements majeurs et infos de dernière minute, nous
        utiliserons une liste de diffusion Whatsapp. Cliquez sur le bouton
        ci-dessous, complétez, et envoyez le message pour vous inscrire sur la
        liste et recevoir les futures infos.
      </p>
      <a
        class="whatsapp-button"
        href="https://wa.me/33676217066?text=Merci%20de%20m%27inscrire%20%C3%A0%20la%20liste%20de%20diffusion.%20Pr%C3%A9nom%20et%20Nom%20%3A"
        target="_blank"
      >
        <SvgWhatsappLogo />
        Recevoir les infos
      </a>
      <p>
        <i
          >Si vous n’avez pas whatsapp,
          <router-link to="/contact">contactez-nous</router-link>.</i
        >
      </p>
      <img
        src="@/assets/motifs/pampas-separator.png"
        class="pampas-separator"
      />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Lieu & Programme</h3>
      <p>Le mariage se déroulera en deux temps le samedi 10 juin:</p>
      <ul>
        <li>
          Pour ceux qui le souhaitent, <b>à 15h</b> aura lieu la cérémonie
          officielle à
          <a href="https://goo.gl/maps/6Bc5jSW9tr1XosnR8" target="_blank"
            >l’annexe de la mairie de Montpellier</a
          >, dans le quartier de Grammont. C’est mignon tout plein, et y’a toute
          la place du monde pour se garer donc pas d’inquiétude.
        </li>
        <br />
        <li>
          Ensuite, il faudra décaler à
          <a href="https://goo.gl/maps/uh6aaXxads5AcE946" target="_blank"
            >la Manade Boch</a
          >, à Lansargues, à 20min en voiture de la mairie. Pour ceux qui ne
          viendraient pas à la mairie, visez <b>16h30</b> à la manade. Ouverture
          des festivités avec rafraîchissements et photos, et vous connaissez la
          suite.
        </li>
      </ul>
      <p>
        Le lendemain on vous propose de se retrouver au même endroit pour se
        remettre de nos émotions autour <b>d’un brunch</b>.
      </p>
      <p>
        Vous l’aurez compris, pour faire le trajet entre la mairie et la Manade,
        organisez-vous par voiture. S’il vous manque une place, ou qu’au
        contraire vous voyagez à vide, n’hésitez pas à
        <router-link to="/contact">nous envoyer un message</router-link>.
      </p>
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
      <img src="@/assets/images/manade-drone.jpeg" class="grid-cell-image" />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
      <img
        src="@/assets/images/manade-exterieur.jpeg"
        class="grid-cell-image"
      />
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 grid-tablet-offset-3"
    >
      <img
        src="@/assets/images/manade-interieur.jpeg"
        class="grid-cell-image"
      />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Logement</h3>
      <p>
        La manade est à proximité de Montpellier, donc vous n’aurez aucun mal à
        trouver gîtes, hôtels, et autres Airbnbs.
      </p>
      <p>
        <b>Attention cependant à l’alcool au volant</b>, et autant vous dire
        qu’il y aura de l’alcool sous toutes ses formes. Donc comme on n’aura
        probablement pas que ça à faire de vous empêcher de prendre la voiture
        si vous êtes beurrés, on a préféré prévoir une alternative :
        <b>le village tipi !</b>
      </p>
      <p>
        Les tipis seront plantés dans le champ de la manade, donc pas de trajet
        à prévoir ! Des douches d’extérieur seront installées, et pour les
        toilettes on aura celles du bâtiment. Comme vous pouvez le voir sur les
        photos, on est pas sur de la tente deux-secondes,
        <i>mais ça reste du camping hein</i>. C’est néanmoins la meilleure
        solution pour un maximum de liberté sur la gestion de votre soirée.
      </p>
      <p>
        Rassurez-vous, un espace dédié sera aménagé avec tout ce qu’il faut pour
        vous préparer et vous faire tout beaux.
      </p>
      <p>
        Si vous êtes intéressés, nous vous demanderons de participer aux frais.
        <b>Ne trainez pas, les places sont limitées</b>. Plus d’infos sur la
        page d’inscription.
      </p>
      <Button route="/tipis" outlined class="centered-cta"
        >Ça m'intéresse !</Button
      >
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 grid-tablet-offset-2"
    >
      <img src="@/assets/images/tipi6.jpeg" class="grid-cell-image" />
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 grid-tablet-offset-2"
    >
      <img src="@/assets/images/village-tipi-2.jpeg" class="grid-cell-image" />
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>RSVP</h3>
      <p>
        Si vous ne pouvez pas venir merci de nous
        <router-link to="/contact">prévenir</router-link> au plus vite.
      </p>
      <p>
        Pour les autres, afin de nous aider à préparer au mieux le week-end,
        merci de nous donner votre confirmation et de nous préciser les éléments
        suivants:
      </p>
      <ul>
        <li>Serez-vous accompagné&nbsp;? (conjoint, bébé)</li>
        <li>
          Venez-vous à la mairie pour 15h&nbsp;? ou directement sur le lieu de
          réception pour 16h30&nbsp;?
        </li>
        <li>Restez-vous pour le brunch le lendemain&nbsp;?</li>
      </ul>
      <p>
        Si vous n’avez pas encore les réponses à toutes ces questions mais à une
        partie seulement ce n’est pas grave, répondez nous en plusieurs fois :)
        Plus tôt on aura ces informations, plus simple ça sera pour nous.
      </p>
      <p>
        Vous pouvez utiliser le formulaire dédié ci-dessous ou
        <router-link to="/contact">nous contacter directement</router-link>.
      </p>
      <Button
        link="https://fr10juin2023.surveysparrow.com/s/rsvp/tt-v2aKLU8feNXshusjsvW5PM"
        target="_blank"
        raised
        class="centered-cta"
        >Confirmer ma présence</Button
      >
      <p class="centered-cta-subtitle">
        Si vous ne pouvez pas venir,
        <router-link to="/contact">prévenez nous directement.</router-link>
      </p>
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <h3>Liste de Mariage</h3>
      <p>
        Comme vous le savez, cela fait maintenant 10 ans qu'on est ensemble (et
        oui, c’est aussi ça qu’on fête!). Du coup on a déjà pas mal de choses
        que l’on retrouve habituellement dans une liste de mariage classique
        (vaisselle, mobilier, déco, système son et j’en passe…).
      </p>
      <p>
        Ce qu’on vous propose à la place, pour ceux qui le souhaitent bien sûr,
        c’est de participer à une cagnotte qui nous servira à organiser notre
        voyage de noces (et à financer une partie du mariage, vous vous en
        doutez).
      </p>
      <p>
        Pour cela nous avons créé une
        <a
          href="https://lydia-app.com/pots?id=67372-cadeau-mariage-cecile-leo"
          target="_blank"
          >cagnotte Lydia</a
        >, sinon il y aura une urne sur place le jour J. Et si vous avez une
        autre idée en tête ne vous privez pas, on aime aussi les surprises!
      </p>
      <p>Merci dans tous les cas 😊</p>
    </div>
  </FloralSection>
</template>

<script>
import FloralSection from "@/components/atoms/FloralSection.vue";
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "HomeView",
  components: { FloralSection, Button },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include gradient-background;
  margin-top: -56px;
  padding-top: 56px;
  text-align: center;

  h1 {
    margin: 50px 0;
    color: white;
    font-weight: 900;
  }

  .mdc-layout-grid {
    position: relative;
  }

  .img-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  img {
    width: 270px;
    opacity: 0;
    max-width: 90%;
    -moz-animation: cssAnimation 200ms ease-in 300ms forwards;
    -webkit-animation: cssAnimation 200ms ease-in 300ms forwards;
    -o-animation: cssAnimation 200ms ease-in 300ms forwards;
    animation: cssAnimation 200ms ease-in 300ms forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}

@keyframes cssAnimation {
  to {
    opacity: 0.7;
  }
}

.first-part {
  text-align: center;

  h4 {
    font-style: italic;
    margin-bottom: 50px;
  }

  .pampas-separator {
    width: 90%;
  }
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;

    h1 {
      margin: 70px 0;
    }

    img {
      width: 345px;
    }
  }
}

@media (min-width: $mdc-grid-desktop) {
  .header {
    h1 {
      font-size: 2.5em;
      margin: 100px 0;
    }

    img {
      width: 475px;
    }
  }
}

.whatsapp-button {
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  padding: 10px 16px;
  color: white;
  font-weight: 700;
  width: fit-content;
  margin: auto;
  transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;

  svg {
    fill: white;
    height: 30px;
    margin-right: 8px;
  }

  &:hover {
    transform: translateY(-4px);
    background-color: #58e18a;
  }
}

.centered-cta-subtitle {
  text-align: center;
  font-style: italic;
  margin-top: -12px;
}
</style>
