<template>
  <aside ref="mdcDrawer" class="mdc-drawer mdc-drawer--modal">
    <div class="mdc-drawer__content" @click="closeDrawer">
      <List>
        <router-link
          class="mdc-list-item"
          to="/"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Accueil</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/infos"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Infos</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/tipis"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Tipis</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/contact"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Contact</span>
        </router-link>
      </List>
    </div>
  </aside>
  <div class="mdc-drawer-scrim"></div>
  <header class="mdc-top-app-bar mdc-top-app-bar--fixed" ref="mdcTopAppBar">
    <div class="mdc-top-app-bar__row">
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
      >
        <div class="mdc-top-app-bar__page-title">{{ $route.name }}</div>
      </section>
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-center"
      >
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/"
        >
          <span>Accueil</span>
        </router-link>
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/infos"
        >
          <span>Infos</span>
        </router-link>
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/tipis"
        >
          <span>Tipis</span>
        </router-link>
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/contact"
        >
          <span>Contact</span>
        </router-link>
      </section>
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        role="toolbar"
      >
        <a
          class="mdc-icon-button mdc-top-app-bar__action-item mdc-top-app-bar__action-item--drawer-toggle"
          @click="openDrawer"
        >
          <SvgBurgerIcon />
        </a>
      </section>
    </div>
  </header>
</template>

<script>
import List from "@/components/atoms/material/List.vue";
import { MDCDrawer } from "@material/drawer";
import { MDCTopAppBar } from "@material/top-app-bar";

export default {
  name: "PageNavigation",
  components: {
    List,
  },
  data() {
    return {
      drawerComponent: null,
      topAppBarComponent: null,
    };
  },
  methods: {
    openDrawer: function () {
      this.drawerComponent.open = true;
    },
    closeDrawer: function () {
      this.drawerComponent.open = false;
    },
  },
  mounted: function () {
    this.drawerComponent = MDCDrawer.attachTo(this.$refs["mdcDrawer"]);
    this.topAppBarComponent = MDCTopAppBar.attachTo(this.$refs["mdcTopAppBar"]);
  },
  unmounted: function () {
    this.drawerComponent.destroy();
    this.topAppBarComponent.destroy();
  },
};
</script>

<style lang="scss">
@use "sass:map";
@import "src/assets/variables";

.wedding-mdc {
  .mdc-top-app-bar {
    align-items: center;
  }

  .mdc-top-app-bar__row {
    max-width: $mdc-layout-grid-max-width;
    padding: 0 map.get($mdc-layout-grid-default-margin, "phone");
  }

  @media (min-width: $mdc-grid-tablet) {
    .mdc-top-app-bar__row {
      padding: 0 map.get($mdc-layout-grid-default-margin, "tablet");
    }
  }

  @media (min-width: $mdc-grid-desktop) {
    .mdc-top-app-bar__row {
      padding: 0 map.get($mdc-layout-grid-default-margin, "desktop");
    }
  }

  .mdc-top-app-bar__section {
    padding: 8px 0;

    &.mdc-top-app-bar__section--align-center {
      justify-content: center;
    }
  }

  /* Drawer Button and titles conditionnal display */

  .mdc-top-app-bar__custom-menu {
    display: none;
  }

  .mdc-top-app-bar__action-item--drawer-toggle {
    fill: black;
  }

  @media (min-width: $theme-drawer-width-threshold) {
    .mdc-top-app-bar__action-item--drawer-toggle,
    .mdc-top-app-bar__page-title {
      display: none;
    }

    .mdc-top-app-bar__custom-menu {
      display: initial;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 890px) {
    .mdc-top-app-bar {
      font-size: 0.9rem;
    }
  }

  .mdc-drawer--modal + .mdc-drawer-scrim {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mdc-drawer {
    border: none;

    .mdc-list-item {
      margin: 8px;
      height: 36px;
      border-radius: 18px;
      display: flex;
      align-items: center;
    }
  }

  .mdc-top-app-bar {
    white-space: nowrap;
    background-color: transparent;
    transition: box-shadow 200ms linear;
    color: black;

    .mdc-top-app-bar__page-title {
      opacity: 0;
    }
  }

  .mdc-top-app-bar:after {
    background: linear-gradient(90deg, #f5f7f5, white, #f5f7f5);
    // background: url("../../../assets/backgrounds/background1.svg") no-repeat
    //   center;
    background-size: cover;
    content: "";
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* TRANSISITION */
    transition: opacity 200ms ease-in-out;
    -webkit-transition: opacity 200ms ease-in-out;
    -moz-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
  }

  .mdc-top-app-bar--fixed-scrolled {
    &:after,
    .mdc-top-app-bar__page-title {
      opacity: 1;
    }
  }

  .mdc-top-app-bar__custom-menu {
    transition: color 150ms ease-in-out;
    &:hover {
      color: $theme-brown;
    }
    &.mdc-top-app-bar__custom-menu--active {
      color: $theme-green;
      font-weight: 600;
    }
  }

  .mdc-top-app-bar__page-title {
    color: $theme-green;
    font-weight: 600;
  }
}
</style>
