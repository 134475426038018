<template>
  <div class="floral-section">
    <img class="--top-left" src="@/assets/motifs/top-left.svg" />
    <img class="--bottom-right" src="@/assets/motifs/bottom-right.svg" />
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.floral-section {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #f5f7f5, white, #f5f7f5);
  padding-top: 50px;
  padding-bottom: 80px;

  img {
    position: absolute;
    &.--top-left {
      top: -82px;
      left: -50px;
      height: 328px;
    }
    &.--bottom-right {
      bottom: -271px;
      right: -125px;
      height: 556px;
    }
  }

  .mdc-layout-grid__inner {
    position: relative;
    padding: 0 20px;
    background: linear-gradient(
      90deg,
      rgba(#f5f7f5, 0.7),
      rgba(white, 0.7),
      rgba(#f5f7f5, 0.7)
    );
    border-radius: 20px;
  }
}

@media (min-width: 450px) {
  .floral-section {
    img {
      &.--top-left {
        top: -95px;
        left: -50px;
        height: 395px;
      }
      &.--bottom-right {
        bottom: -297px;
        right: -125px;
        height: 610px;
      }
    }
  }
}

@media (min-width: 840px) {
  .floral-section {
    img {
      &.--top-left {
        top: -105px;
        left: -50px;
        height: 456px;
      }
      &.--bottom-right {
        bottom: -329px;
        right: -154px;
        height: 701px;
      }
    }
  }
}

@media (min-width: 1300px) {
  .floral-section {
    img {
      &.--top-left {
        top: -105px;
        left: -50px;
        height: 567px;
      }
      &.--bottom-right {
        bottom: -430px;
        right: -177px;
        height: 900px;
      }
    }
  }
}
</style>
