<template>
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <div v-scrollreveal class="sr_prevent_flickering">
            <h1>Nous Contacter</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FloralSection class="contact">
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <p>
        Voici nos coordonnées pour nous contacter directement en cas de besoin:
      </p>
      <div class="contact-info">
        <b>Cécile</b> -
        <a href="tel:+33685167965" class="contact-info__tel"
          >+33(0)6 85 16 79 65</a
        >
        -
        <a href="mailto:cecile-barnoud@orange.fr">cecile-barnoud@orange.fr</a>
      </div>
      <div class="contact-info">
        <b>Léo</b> -
        <a href="tel:+33676217066" class="contact-info__tel"
          >+33(0)6 76 21 70 66</a
        >
        -
        <a href="mailto:leo.pons@wanadoo.fr">leo.pons@wanadoo.fr</a>
      </div>
      <img src="@/assets/images/selfie-gif.gif" />
    </div>
  </FloralSection>
</template>

<script>
import FloralSection from "@/components/atoms/FloralSection.vue";

export default {
  name: "ContactView",
  components: { FloralSection },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include gradient-background;
  margin-top: -56px;
  padding-top: 56px;
  text-align: center;

  h1 {
    margin: 50px auto;
    color: white;
    font-weight: 900;
  }
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.contact {
  text-align: center;

  .contact-info {
    margin-bottom: 10px;

    a {
      color: $theme-brown;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }

      &.contact-info__tel {
        color: $theme-green;
      }
    }
  }

  img {
    width: 350px;
    border-radius: 20px;
    max-width: 100%;
    margin: 30px auto;
  }
}
</style>
